import React, { useEffect, useMemo } from "react";

import { isEmpty, isNil, prop } from "ramda";

import { PROCESS_STATUS } from "constants/process";
import { isAboutToExpired as isAboutToExpiredFn, diffFromNow } from "helpers/TimeFormatter";
import { useAlerts, useFullCertificationData, useKycData, useUser, useVirtualAssetsData } from "hooks";

import { Alert } from "./Alert";
import { EddAlert } from "./EddAlert";
import { EddRejectionAlert } from "./EddRejectionAlert";
import { KycAlert } from "./KycAlert";
import { RejectionAlert } from "./RejectionAlert";
import { UkRestrictedPromotionAlert } from "./UkRestrictedPromotionAlert";

type AlertsContainerProps = {
  hideCertificationAlters?: boolean;
  hideVerificationAlerts?: boolean;
};

type CertificationHooksArgs = {
  hideCertificationAlters?: boolean;
  hideVerificationAlerts?: boolean;
};

export const AlertsContainer = ({ hideCertificationAlters, hideVerificationAlerts }: AlertsContainerProps) => {
  const { alerts, closeAlert } = useAlerts();

  useEddAlerts();
  usePromotionAlerts();
  useKycAlerts({ hideVerificationAlerts });
  useVirtualAssetsAlerts({ hideCertificationAlters });
  useSelfCertificationAlerts({ hideCertificationAlters });
  useFullCertificationAlerts({ hideCertificationAlters });

  return (
    <>
      {alerts.filter(prop("isVisible")).map(({ id, Element, props }) => {
        return (
          <Element
            {...props}
            key={id}
            onClose={(e) => {
              closeAlert(id);
              props?.onClose?.(e);
            }}
          />
        );
      })}
    </>
  );
};

const useEddAlerts = () => {
  const { data: userData, isEDDProcessing } = useUser();
  const { addAlert, removeAlert } = useAlerts();
  const eddKey = "edd-alert";

  const { edd } = userData;

  useEffect(() => {
    if (isNil(userData)) return;

    const removeAlertFn = () => removeAlert(eddKey);

    removeAlertFn();

    if (edd.currentStatus === PROCESS_STATUS.rejected) {
      addAlert(eddKey, EddRejectionAlert, {
        status: edd.currentStatus,
        rejectionReasons: edd.rejectionReasons,
      });
      return removeAlertFn;
    }

    if (isEDDProcessing) {
      addAlert(eddKey, EddAlert, {
        status:
          edd.currentStatus !== PROCESS_STATUS.accepted &&
          edd.currentStatus !== PROCESS_STATUS.expired &&
          edd.lastStatus !== PROCESS_STATUS.draft
            ? edd.currentStatus
            : edd.lastStatus,
        rejectionReasons: edd.rejectionReasons,
      });
      return removeAlertFn;
    }
  }, [userData, edd]);
};

const usePromotionAlerts = () => {
  const { data: userData, isPromotionRequestProcessing, isEDDBlocking } = useUser();
  const { addAlert, removeAlert } = useAlerts();
  const key = "promotion-alert";

  useEffect(() => {
    if (isNil(userData)) return;

    const removeAlertFn = () => removeAlert(key);

    removeAlertFn();

    if (isPromotionRequestProcessing && !isEDDBlocking) {
      addAlert(key, UkRestrictedPromotionAlert);

      return removeAlertFn;
    }
  }, [userData]);
};

const useKycAlerts = ({ hideVerificationAlerts }: CertificationHooksArgs) => {
  const key = "kyc-alert";
  const aboutToExpireKey = key + "-about-to-expire";
  const expiredKey = key + "-expired";

  const { data: userData, isEDDProcessing, isVerificationBlocked, isEDDBlocking } = useUser();

  const { kyc } = userData;
  const isRejected = useMemo(() => {
    if (kyc.lastStatus == "pending" || kyc.lastStatus == "submitted") return false;

    return kyc.currentStatus === "rejected" || kyc.previousStatus === "rejected";
  }, [kyc.currentStatus, kyc.lastStatus, kyc.previousStatus]);

  const isAboutToExpired = useMemo(() => {
    return (
      userData.kyc.expiresAt &&
      ([PROCESS_STATUS.submitted, PROCESS_STATUS.draft] as string[]).includes(kyc.lastStatus) &&
      kyc.currentStatus === PROCESS_STATUS.accepted &&
      isAboutToExpiredFn(userData.kyc.expiresAt)
    );
  }, [userData, kyc.currentStatus, kyc.lastStatus]);

  const isExpired = useMemo(() => {
    if (kyc.lastStatus == "pending" || kyc.lastStatus == "submitted") return false;

    return kyc.currentStatus === "expired";
  }, [kyc.currentStatus, kyc.lastStatus]);
  const { addAlert, removeAlert } = useAlerts();

  const removeAlerts = () => {
    removeAlert(key);
    removeAlert(aboutToExpireKey);
    removeAlert(expiredKey);
  };

  useEffect(() => {
    if (isEDDBlocking) {
      removeAlerts();

      return;
    }

    if (isVerificationBlocked) {
      addAlert(key, Alert, {
        type: "error",
        message: "ID Verification Blocked",
        description: (
          <span>
            Max attempts reached: Identity Verification Blocked.{" "}
            <a href={process.env.REACT_APP_CORE_SUPPORT} target='_blank' rel='noreferrer'>
              Please contact support
            </a>
            .
          </span>
        ),
      });
      return;
    }

    removeAlerts();

    if (!hideVerificationAlerts) {
      if (isRejected && kyc) {
        addAlert(key, RejectionAlert, {
          rejectionReasons: kyc.rejectionReasons,
          header: "ID Verification Rejected",
          description: "Check out the following possible reasons of verification being rejected",
        });
      }

      if (isAboutToExpired) {
        const currentDate = new Date();
        const expiresAtDate = new Date(userData.kyc.expiresAt);

        if (expiresAtDate.getTime() < currentDate.getTime()) {
          addAlert(aboutToExpireKey, KycAlert, {
            status: "about-to-expired-past",
            titleParam: diffFromNow(userData.kyc.expiresAt),
          });
        } else {
          addAlert(aboutToExpireKey, KycAlert, {
            status: "about-to-expired",
            titleParam: diffFromNow(userData.kyc.expiresAt),
          });
        }
      }

      if (isExpired) {
        addAlert(key + "-expired", KycAlert, { status: "expired" });
      }
    }
  }, [kyc, userData, isEDDProcessing]);
};

const useVirtualAssetsAlerts = ({ hideCertificationAlters }: CertificationHooksArgs) => {
  const { data: userData, isEDDBlocking } = useUser();
  const { virtualAssets } = userData;
  const { addAlert, removeAlert } = useAlerts();

  useEffect(() => {
    if ((!hideCertificationAlters && isNil(userData)) || isEDDBlocking) return;
    const key = "va-pending";

    if (
      (!hideCertificationAlters && virtualAssets.currentStatus === "rejected") ||
      virtualAssets.lastStatus === "rejected"
    ) {
      addAlert(key, RejectionAlert, {
        rejectionReasons: virtualAssets.rejectionReasons,
        header: "Suitability Information Submission Rejected",
        description: "Check out the following possible reasons of verification being rejected",
      });
      return;
    } else {
      removeAlert(key);
    }
  }, [virtualAssets]);
};

const useFullCertificationAlerts = ({ hideCertificationAlters }: CertificationHooksArgs) => {
  const { data: userData, isEDDBlocking } = useUser();
  const { addAlert, removeAlert } = useAlerts();
  const { fullCertification } = userData;

  const forcedCertificationExpiration = userData.forceSelfCertification;

  useEffect(() => {
    const key = "full-certification-rejection-alert";

    if (
      !hideCertificationAlters &&
      !forcedCertificationExpiration &&
      (fullCertification.currentStatus === "expired" || fullCertification.previousStatus === "expired") &&
      fullCertification.lastStatus !== "pending"
    ) {
      addAlert(key, RejectionAlert, {
        description:
          "The documents previously used to certify your investor status have expired, and your status has been updated to Self-Certification. To maintain your Certification status, kindly submit a new application.",
        header: "Full Certification Expired:",
      });

      return;
    }

    if (
      (!hideCertificationAlters && isNil(fullCertification)) ||
      isEDDBlocking ||
      fullCertification.lastStatus === "pending"
    ) {
      removeAlert(key);
      return;
    }

    if (
      !hideCertificationAlters &&
      !isNil(fullCertification.rejectionReasons) &&
      !isEmpty(fullCertification.rejectionReasons)
    ) {
      addAlert(key, RejectionAlert, {
        rejectionReasons: fullCertification.rejectionReasons,
        description: "Check out the following possible reasons of certification being rejected",
        header: "Full Certification Submission Rejected",
      });

      return;
    }

    removeAlert(key);
  }, [userData]);
};

const useSelfCertificationAlerts = ({ hideCertificationAlters }: CertificationHooksArgs) => {
  const { data: userData } = useUser();
  const { addAlert, removeAlert } = useAlerts();

  useEffect(() => {
    const key = "self-certification-rejection-alert";
    if (
      !hideCertificationAlters &&
      userData.forceSelfCertification &&
      userData.selfCertification.currentStatus === "expired"
    ) {
      addAlert(key, RejectionAlert, {
        description:
          "As per our policy, we require you to resubmit a self-certification before advancing to the Full certification process.",
        header: "Self-Certification Resubmission Required",
      });
      return;
    }

    removeAlert(key);
  }, [userData]);
};
